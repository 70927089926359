<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<div class="row mt-4">
			<div class="col-12 d-flex">
				<div class="h2 text-muted py-3">
					{{ translate('your_account_has_created') }}
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<ul class="list-group w-100">
					<li class="list-group-item">
						<div class="row">
							<div
								id="content"
								class="col-sm-12 text-center"
								style="margin: 30px 0 60px;">
								<i class="fas fa-check-circle fa-10x success-icon mb-4" />
								<div class="success-message margin-20">
									<!-- eslint-disable-next-line -->
									<h3 v-html="translate('success_title')" />
									<hr>
									<!-- eslint-disable-next-line -->
									<p v-html="translate('success_text_1')" />
									<!-- eslint-disable-next-line -->
									<p v-html="translate('success_text_2', {mailto:companyEmail, companyEmail})" />
								</div>
								<div class="clearfix text-center">
									<b-button
										variant="primary"
										@click="$router.push({ name: 'Home' }, () => $router.go(0))">
										{{ translate('go_to_dashboard') }}
									</b-button>
								</div>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
		<div class="row">
			<div class="col-4 ml-auto my-4" />
		</div>
	</div>
</template>

<script>
import DocumentTitle from '@/mixins/DocumentTitle';
import { ORDER_VERIFICATION_PHONES } from '@/settings/Purchase';
import { NO_DAY_LIMIT_CASH_PAYMENT } from '@/settings/Country';
import commonMix from './mixins/Common';

export default {
	name: 'BecomeDistributorSuccess',
	mixins: [commonMix, DocumentTitle],
	props: {
		paymentMethodName: {
			type: String,
			default: '',
		},
		verificationRequired: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			companyName: process.env.VUE_APP_TITLE,
			companyEmail: process.env.VUE_APP_COMPANY_EMAIL,
			alert: new this.$Alert(),
		};
	},
	mounted() {
		if (this.paymentMethodName === 'checkmo') {
			const alertTrans = `cash_payments_popup_description${NO_DAY_LIMIT_CASH_PAYMENT.includes(this.country) ? '_no_day_limit' : ''}`;
			this.alert.success(this.translate('cash_payments_popup_title'), this.translate(alertTrans, { displayedLink: this.companyEmail, supportLink: this.companyEmail }), true);
		}
		if (this.verificationRequired) {
			const phoneNumbers = [].concat(ORDER_VERIFICATION_PHONES);
			const last = phoneNumbers.pop();
			const phoneList = phoneNumbers.length > 0
				? `${phoneNumbers.join(', ')} ${this.translate('or')} ${last}`
				: last;

			this.alert.success(this.translate('verbal_verification_popup_title'), this.translate('verbal_verification_existing_popup_description', {
				companyName: process.env.VUE_APP_TITLE,
				phoneNumber: phoneList,
			}), true);
		}
	},
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			if (from.name === 'BecomeDistributorConfirmation' && to.query.wallet_address) {
				vm.$router.replace({ name: 'CryptoPaymentsDetails', params: { wallet_address: to.query.wallet_address } });
			} else if (from.name !== 'BecomeDistributorConfirmation') {
				vm.$router.replace({ name: 'Login' });
			}
		});
	},
};
</script>
<style scoped>
.success-icon::before {
	color: #64B64B !important;
}
</style>
